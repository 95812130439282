<template>
  <JetDialogData
    title="Добавить автоперевозчика"
    width="900"
    v-model="dialog"
    :current-tab="currentTab"
  >
    <!-- Основная страница -->
    <template #tab-1>
      <!-- Строка с выбором организации и дат -->
      <v-layout column>
        <!-- Строка с выбором дат -->
        <v-flex>
          <v-layout>
            <!-- Дата начала настройки -->
            <v-flex>
              <v-menu
                offset-y
                transition="scale-transition"
                max-width="290px"
                max-height="290px"
                v-model="startDate.showPicker"
                :close-on-content-click="false"
              >
                <template #activator="{ on }">
                  <v-text-field
                    readonly persistent-hint hide-details
                    label="Дата начала настройки"
                    prepend-icon="mdi-calendar"
                    v-on="on"
                    :value="formatStartDate"
                  ></v-text-field>
                </template>

                <v-date-picker
                  no-title
                  v-model="startDate.value"
                  @input="startDate.showPicker = false"
                  :first-day-of-week="1"
                  :weekday-format="dayOfWeekToFormat"
                ></v-date-picker>
              </v-menu>
            </v-flex>

            <v-flex/>

            <!-- Дата окончания настройки -->
            <v-flex>
              <v-menu
                offset-y
                transition="scale-transition"
                max-width="290px"
                max-height="290px"
                v-model="endDate.showPicker"
                :close-on-content-click="false"
              >
                <template #activator="{ on }">
                  <v-text-field
                    readonly persistent-hint hide-details clearable
                    label="Дата окончания настройки"
                    prepend-icon="mdi-calendar"
                    v-on="on"
                    :value="formatEndDate"
                  ></v-text-field>
                </template>

                <v-date-picker
                  no-title
                  v-model="endDate.value"
                  :min="startDate.value"
                  @input="endDate.showPicker = false"
                  :first-day-of-week="1"
                  :weekday-format="dayOfWeekToFormat"
                ></v-date-picker>
              </v-menu>
            </v-flex>
          </v-layout>
        </v-flex>

        <!-- Строка с выбором организации -->
        <v-flex class="mt-2">
          <v-autocomplete
            return-object
            label="Организация"
            item-value="id"
            item-text="title"
            v-model="organization.selected"
            :items="organization.data"
            :loading="organization.loading"

          >
            <template #item="{item: org}">
              <v-list-item-content>
                <div id="selectForAddCarrier">
                  <div>
                    {{ org.id === 'headerForOrganizations' ? org.creationdate : formatDate(org.creationdate) }}
                  </div>
                  <div>
                    {{ org.id === 'headerForOrganizations' ? org.enddt : formatDate(org.enddt) }}
                  </div>
                  <div>
                    {{ org.name }}
                  </div>
                  <div>
                    {{ org.title }}
                  </div>
                  <div style="justify-self: center; align-self: center">
                    <span v-if="org.id === 'headerForOrganizations'">
                      {{org.blocked}}
                    </span>
                    <v-checkbox
                      v-else
                      v-model="org.blocked"
                      class="mt-0 pt-0"
                      disabled hide-details
                    ></v-checkbox>
                  </div>
                </div>
              </v-list-item-content>
            </template>
          </v-autocomplete>
        </v-flex>

        <!-- Строка с кнопками -->
        <v-flex>
          <!-- Добавить перевозчика -->
          <v-btn
            color="primary"
            :disabled="organization.selected == null"
            @click="onAddCarrier"
          >
            Добавить перевозчика
          </v-btn>
        </v-flex>
      </v-layout>

      <!-- Строка с таблицей -->
      <v-layout>
        <v-flex class="mt-4">
          <v-data-table
            fixed-header
            style="width: 100%;"
            height="400"
            :headers="table.headers"
            :items="table.data"
          >
            <!-- Организация -->
            <template #item.org="{ item }">
              {{ item.org.substring(0, 25) }} {{ item.org.length > 25 ? '...' : '' }}
            </template>

            <!-- Перевозчик -->
            <template #item.carrier="{ item }">
              {{ item.carrier.substring(0, 25) }} {{ item.carrier.length > 25 ? '...' : '' }}
            </template>

            <!-- Блок действий -->
            <template #item.actions="{ item }">
              <!-- Удалить -->
              <v-tooltip bottom>
                <template #activator="{ on, attrs }">
                  <v-btn
                    fab x-small depressed
                    v-on="on"
                    v-bind="attrs"
                    @click="showRemoveDialog(item)"
                  >
                    <v-icon color="red">
                      mdi-close
                    </v-icon>
                  </v-btn>
                </template>

                Удалить
              </v-tooltip>
            </template>
          </v-data-table>
        </v-flex>
      </v-layout>

      <!-- Строка с кнопкой Применить -->
      <v-layout>
        <v-flex></v-flex>

        <v-flex shrink>
          <v-btn
            class="mt-3 ml-4"
            color="primary"
            @click="onApplyClick"
          >
            Применить
          </v-btn>
        </v-flex>
      </v-layout>
    </template>

    <!-- Список перевозчиков -->
    <template #tab-2>
      <v-layout column>
        <v-flex class="px-4 py-1 body-2">
          <v-layout>
            <v-flex align-self-center>Для выделенных перевозчиков уже существует связь с организацией</v-flex>
            <v-flex align-self-center>
              <v-text-field
                label="Поиск перевозчика"
                v-model="carriersTable.filter"
                hide-details
                clearable
                class="pt-0 mt-0"
              ></v-text-field>
            </v-flex>
          </v-layout>
        </v-flex>

        <v-flex>
          <v-data-table
            show-select
            height="400"
            v-model="carriersTable.selected"
            :items="carriersTable.data"
            :headers="carriersTable.headers"
            :item-class="item => !item.isSelectable ? 'c_selected' : ''"
            :search="carriersTable.filter"
          ></v-data-table>
        </v-flex>

        <v-flex class="mt-4">
          <v-layout>
            <v-flex></v-flex>

            <v-flex shrink>
              <v-btn color="primary" @click="onCarriersSelect">
                Выбрать
              </v-btn>

              <v-btn color="primary" class="ml-4" @click="onCarrierCancel">
                Отмена
              </v-btn>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
    </template>

    <!-- Диалог удаления записи -->
    <JetDialog
      title="Подтвердите действие"
      v-model="deleteDialog.show"
      @on-cancel-click="onRemoveCancelClick"
      @on-apply-click="onRemoveApplyClick"
    >
      Вы уверены что хотите удалить перевозчика?
    </JetDialog>
  </JetDialogData>
</template>

<script>
// Сторонние зависимости
import md5 from 'blueimp-md5';

// Утилиты
import {prepareSinRequest, prepareSinResponse} from '@/utils/http';
import {findObjectIndex} from '@/utils/utils';

// Миксины
import BaseAction from '@/components/actions/BaseAction';

// Компоненты
import JetDialog from '@/components/JetDialog';
import JetDialogData from '@/components/JetDialogData';
import { dayOfWeekToFormat, FORMAT_FOR_USER, formatDate } from '@/services/JetDate';

const _MAIN_TAB = 0;
const _ADD_CARRIER_TAB = 1;

export default {
  name: 'AddCarrier',
  components: {
    JetDialog,
    JetDialogData,
  },
  mixins: [
    BaseAction,
  ],
  watch: {
    organization: {
      deep: true,
      async handler(val) {
        if (val.selected !== null) {
          let query = 'sin2:/v:faba5be0-feae-450f-b7ef-83fd830ac20b/';

          query += `?filter=and(`;
          query += `eq(field(".tenant"), param("${val.selected.id}", "id"))`;
          query += `)`;

          await this.$store.dispatch('cache/removeItem', md5('core-read' + query));

          this.existLinks = prepareSinResponse(
            await prepareSinRequest(
              'core-read',
              query
            ),
            {
              hideUnderscores: true,
            }
          );

          this.carriersTable.data = this.carriersTable.data.map(it => {
            it.isSelectable = true;

            return it;
          });

          this.existLinks.forEach(it => {
            const index = this.carriersTable.data.findIndex(cit => {
              return cit.id === it.carrierid;
            });

            if (index > -1 && it.vehicle === null) {
              this.carriersTable.data[index].isSelectable = false;
            }
          });

          this.toDeleteLinks = [];
        } else {
          this.existLinks = [];
          this.toDeleteLinks = [];
        }
      },
    },
  },
  data: () => ({
    // Флаг для отображения диалога
    dialog: true,
    // Начало даты
    startDate: {
      // Показ выбора даты
      showPicker: false,
      // Выбранная дата
      value: new Date().toISOString().substr(0, 10),
    },
    // Окончания даты
    endDate: {
      // Показ выбора даты
      showPicker: false,
      // Выбранная дата
      value: null,
    },
    // Настройка таблицы выбора перевозчиков
    table: {
      // Заголовки
      headers: [
        {text: 'Дата начала', value: 'startDt'},
        {text: 'Дата окончания', value: 'endDt'},
        {text: 'Организация', value: 'org'},
        {text: 'Перевозчик', value: 'carrier'},
        {text: '', value: 'actions', sortable: false},
      ],
      // Данные
      data: [],
    },
    // Настройка таблицы перевозчиков
    carriersTable: {
      // Флаг что данные загружаются
      loading: false,
      // Данные
      data: [],
      // Описание заголовков
      headers: [
        {text: 'Код перевозчика', value: 'orgidOrgcode'},
        {text: 'Перевозчик', value: 'orgidShortname'},
      ],
      // Выбранные перевозчики
      selected: [],
      // Фильтрация перевозчиков
      filter: null,
    },
    // Организация
    organization: {
      // Список
      data: [],
      // Признак что происходит загрузка
      loading: false,
      // Выбраная запись
      selected: null,
    },
    // Текущий таб
    currentTab: 0,
    // Диалог удаления
    deleteDialog: {
      // Флаг для отображения
      show: false,
      // Удаляемый элемент
      item: null,
    },
    // Уже существующие связи
    existLinks: [],
    // Связи которые необходимо удалить
    toDeleteLinks: [],
    dayOfWeekToFormat: () => '',
  }),
  created() {
    this.dayOfWeekToFormat = dayOfWeekToFormat;
  },
  mounted() {
    this.loadData();
  },
  methods: {
    formatDate(date) {
      return formatDate(date, FORMAT_FOR_USER.ONLY_DATE, true);
    },
    // @Override
    baseActionCheck_() {
      return false;
    },
    // Загрузка данных
    loadData() {
      this.organization.loading = true;
      this.carriersTable.loading = true;

      // Получение списка организаций
      prepareSinRequest(
        'core-read',
        'sin2:/v:bec3f50f-77f6-47a4-a7c8-95554cbb2579/?hierarchy=false',
      ).then(request => {
        const res = prepareSinResponse(
          request,
          {
            hideUnderscores: true,
          }
        );

        this.organization.data = [
          {
            creationdate: 'Дата создания',
            enddt: 'Дата окончания',
            name: 'Код',
            title: 'Наименование',
            blocked: 'Блокировка',
            disabled: true,
            id: 'headerForOrganizations',
          },
          ..._copy(res),
        ];
      }).finally(() => {
        this.organization.loading = false;
      });

      // Получение списка перевозчиков
      prepareSinRequest(
        'core-read',
        'sin2:/v:15e0fc9d-421c-4bc8-b1a3-0788bec32400'
      ).then(request => {
        const result = prepareSinResponse(
          request,
          {
            hideUnderscores: true,
          }
        );

        this.carriersTable.data = _copy(result);
      }).finally(() => {
        this.carriersTable.loading = false;
      });
    },
    // Добавить перевозчика
    onAddCarrier() {
      const startDate = new Date(this.startDate.value);
      const endDate = new Date(this.startDate.value);

      if (endDate !== null && startDate - endDate < 0) {
        this.showMessage(
          'warning',
          'Дата окончания не может быть раньше даты начала'
        );

        return;
      }

      this.currentTab = _ADD_CARRIER_TAB;
    },
    // Клик по кнопке "Применить"
    onApplyClick() {
      const toDeletePromises = [];

      if (this.toDeleteLinks.length) {
        this.toDeleteLinks.forEach(it => {
          const promise = jet.http.post('/rpc?d=jsonRpc', {
            type: 'core-delete',
            query: 'sin2:/v:faba5be0-feae-450f-b7ef-83fd830ac20b',
            params: [
              {id: 'id', type: 'id', value: it},
            ],
          });

          toDeletePromises.push(promise);
        });
      }

      this.toDeleteLinks = [];

      Promise.all(toDeletePromises)
        .then(() => {
          const promises = [];

          for (const it of this.table.data) {
            const params = [
              {id: 'id', type: 'id', value: jet.utils.uuidv4()},
              {id: 'vcVehicleRights.carrierID', type: 'id', value: it.carrierId},
              {id: 'vcVehicleRights.crud', type: 'string', value: 'r'},
              {id: 'vcVehicleRights.startDt', type: 'date', value: new Date(this.startDate.value).getTime()},
              {
                id: 'vcVehicleRights.endDt',
                type: 'date',
                value: this.endDate.value === null ? null : new Date(this.endDate.value).getTime(),
              },
              {id: 'vcVehicleRights.tenant', type: 'id', value: it.orgId},
            ];

            const promise = jet.http.post('/rpc/?d=jsonRpc', {
              type: 'core-create',
              query: 'sin2:/v:faba5be0-feae-450f-b7ef-83fd830ac20b',
              params: params,
            });

            promises.push(promise);
          }

          Promise.all(promises)
            .then(() => {
              this.collection.refresh();
              this.dialog = false;
            })
            .catch(err => {
              console.error('err', err);
            });
        });
    },
    // Выбор перевозчиков
    async onCarriersSelect() {
      this.currentTab = _MAIN_TAB;

      for (const it of this.carriersTable.selected) {
        const index = this.existLinks.findIndex(elIt => {
          return it.id === elIt.carrierid;
        });

        if (index > -1) {
          const exist = _copy(this.existLinks[index]);

          const oname = this.organization.selected.title;
          const cname = it.orgidShortname;
          const vehicleNames = _copy(this.existLinks)
            .map(el => {
              if (el.carrierid === exist.carrierid && el.tenant === exist.tenant) {
                return el;
              }

              return null;
            })
            .filter(el => el !== null)
            .map(el => el.vehicleGovnum)
            .join(', ');

          let msg = `Для организации [${oname}] уже заведен перевозчик [${cname}] с ТС [${vehicleNames}].\n`;
          msg += 'Записи с ТС для данного перевозчика будут удалены при сохранении. Продолжить?';

          const answer = await jet.confirm({
            title: 'Предупреждение',
            msg: msg,
          });

          if (answer) {
            this.existLinks.forEach(lit => {
              if (lit.carrierid === exist.carrierid && lit.tenant === exist.tenant) {
                if (this.toDeleteLinks.findIndex(ii => ii === lit.id) === -1) {
                  this.toDeleteLinks.push(lit.id);
                }
              }
            });
          } else {
            return;
          }
        }

        const data = {
          startDt: this.startDate.value,
          endDt: this.endDate.value,
          org: this.organization.selected.title,
          orgId: this.organization.selected.id,
          carrier: it.orgidShortname,
          carrierId: it.id,
        };

        if (findObjectIndex(data, this.table.data) === -1) {
          this.table.data.push(data);
        }
      }

      this.carriersTable.selected = [];
    },
    // Отмена выбора перевозчиков
    onCarrierCancel() {
      this.currentTab = _MAIN_TAB;

      this.carriersTable.selected = [];
    },
    // Показ диалога на удаление записи
    showRemoveDialog(item) {
      this.deleteDialog = {
        show: true,
        item: _copy(item),
      };
    },
    // Отмена удаления записи
    onRemoveCancelClick() {
      this.deleteDialog.show = false;
      this.deleteDialog.item = null;
    },
    // Удаление записи
    onRemoveApplyClick() {
      if (this.deleteDialog.item !== null) {
        const index = findObjectIndex(this.deleteDialog.item, this.table.data);

        if (index > -1) {
          this.table.data.splice(index, 1);
        }
      }

      this.deleteDialog = {
        show: false,
        item: null,
      };
    },
    // Форматирование дат
    _formatDate(date) {
      if (!date) {
        return null;
      }

      const [year, month, day] = date.split('-');

      return `${day}.${month}.${year}`;
    },
  },
  computed: {
    // Форматированная дата начала
    formatStartDate() {
      return this._formatDate(this.startDate.value);
    },
    // Форматированная дата окончания
    formatEndDate() {
      return this._formatDate(this.endDate.value);
    },
  },
}
</script>

<style scoped>
  .c_selected {
    background: lightgray;
  }
</style>

<style lang="sass">
#selectForAddCarrier
  display: grid
  grid-template-columns: 1fr 1fr 1.2fr 1.5fr 0.5fr
  width: 100%
  max-width: 800px

#selectForAddCarrier > div
  padding: 7px
  max-height: 50px
  overflow: hidden
  text-overflow: ellipsis
  white-space: nowrap
</style>
